import React from "react";

const Instagram = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 25"
      className="fill-none width w-6 h-[1.5625rem]"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3.625H8C5.23858 3.625 3 5.86358 3 8.625V16.625C3 19.3864 5.23858 21.625 8 21.625H16C18.7614 21.625 21 19.3864 21 16.625V8.625C21 5.86358 18.7614 3.625 16 3.625ZM19.25 16.625C19.2445 18.4176 17.7926 19.8695 16 19.875H8C6.20735 19.8695 4.75549 18.4176 4.75 16.625V8.625C4.75549 6.83235 6.20735 5.38049 8 5.375H16C17.7926 5.38049 19.2445 6.83235 19.25 8.625V16.625ZM16.75 8.875C17.3023 8.875 17.75 8.42728 17.75 7.875C17.75 7.32272 17.3023 6.875 16.75 6.875C16.1977 6.875 15.75 7.32272 15.75 7.875C15.75 8.42728 16.1977 8.875 16.75 8.875ZM12 8.125C9.51472 8.125 7.5 10.1397 7.5 12.625C7.5 15.1103 9.51472 17.125 12 17.125C14.4853 17.125 16.5 15.1103 16.5 12.625C16.5027 11.4307 16.0294 10.2846 15.1849 9.44008C14.3404 8.59559 13.1943 8.12234 12 8.125ZM9.25 12.625C9.25 14.1438 10.4812 15.375 12 15.375C13.5188 15.375 14.75 14.1438 14.75 12.625C14.75 11.1062 13.5188 9.875 12 9.875C10.4812 9.875 9.25 11.1062 9.25 12.625Z"
        className="fill-black"
      />
    </svg>
  );
};

export default Instagram;
