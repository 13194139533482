export const {
  // Project
  VITE_APPWRITE_API_ENDPOINT: API_ENDPOINT,
  VITE_APPWRITE_API_PROJECT_ID: PROJECT_ID,

  // Databases
  VITE_APPWRITE_DATABASE_ID: DATABASE_ID,

  // Teams
  VITE_APPWRITE_SYSTEM_TEAM_ID: SYSTEM_TEAM_ID,

  // Collections
  VITE_APPWRITE_INVOICES_COLLECTION_ID: INVOICES_COLLECTION_ID,
  VITE_APPWRITE_DISTRIBUTIONS_COLLECTION_ID: DISTRIBUTIONS_COLLECTION_ID,
  VITE_APPWRITE_BILLING_ADDRESS_COLLECTION_ID: BILLING_ADDRESS_COLLECTION_ID,
  VITE_APPRWITE_TRANSACTIONS_COLLECTION_ID: TRANSACTIONS_COLLECTION_ID,
  VITE_APPWRITE_MONTHLY_STATEMENTS_COLLECTION_ID:
    MONTHLY_STATEMENTS_COLLECTION_ID,
  VITE_APPWRITE_ACCOUNT_SUMMARIES_COLLECTION_ID:
    ACCOUNT_SUMMARIES_COLLECTION_ID,
  VITE_APPWRITE_CATEGORIES_COLLECTION_ID: CATEGORIES_COLLECTION_ID,
  VITE_APPWRITE_MONTHLY_CATEGORY_TOTALS_COLLECTION_ID:
    MONTHLY_CATEGORY_TOTALS_COLLECTION_ID,
  VITE_APPWRITE_SHARED_INVOICES_COLLECTION_ID: SHARED_INVOICES_COLLECTION_ID,
  VITE_APPRWITE_ITEMS_COLLECTION_ID: ITEMS_COLLECTION_ID,
  VITE_APPWRITE_ORGANISATIONS_COLLECTION_ID: ORGANISTIONS_COLLECTION_ID,
  VITE_APPWRITE_PROFILES_COLLECTION_ID: PROFILES_COLLECTION_ID,

  // Buckets
  VITE_APPWRITE_LOGOS_BUCKET_ID: LOGOS_BUCKET_ID,
  VITE_APPWRITE_AVATARS_BUCKET_ID: AVATARS_BUCKET_ID,
} = import.meta.env;
