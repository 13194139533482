import React from "react";
import cn from "../../../utils/cn";

function LogoCondensed({ className, variant = "black" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 33"
      className={cn(className, "fill-none")}
    >
      <path
        fill="#0D6EFD"
        d="M10.594 2.646c4.209 0 7.437 1.155 9.687 3.464 2.25 2.31 3.374 5.514 3.374 9.613 0 4.619-1.488 8.147-4.463 10.586a9.502 9.502 0 01-3.773 1.946c-1.403.337-3.011.506-4.825.506H0v-1.907l2.213-.194.58-.623V5.37l-.58-.623L0 4.554V2.646h10.594zm-3.011 2.18v21.756h2.104c5.708 0 8.562-3.633 8.562-10.898 0-7.239-2.854-10.858-8.562-10.858H7.583zM30.65 0h1.742v11.13c1.96-1.167 3.713-1.75 5.26-1.75 1.38 0 2.6.376 3.665 1.128 1.089.727 1.935 1.752 2.54 3.075.629 1.323.943 2.867.943 4.631 0 2.257-.496 4.203-1.487 5.838-.968 1.634-2.383 2.906-4.245 3.814-1.33.623-3.386.934-6.168.934h-.98l-4.027-.078V3.347h-2.612V1.829L30.651 0zm1.742 26.66c.194.025.387.038.58.038h.581c2.201 0 3.81-.61 4.825-1.829 1.04-1.22 1.56-3.1 1.56-5.643 0-2.231-.46-3.918-1.378-5.06-.92-1.167-2.213-1.75-3.882-1.75-.315 0-.665.025-1.052.077s-.799.13-1.234.233V26.66z"
      ></path>
      <path
        d="M0 32h44.8"
        className={cn({
          "stroke-[#0D6EFD]": variant === "blue",
          "stroke-[#111111]": variant === "black",
        })}
      ></path>
    </svg>
  );
}

export default LogoCondensed;
